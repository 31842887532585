var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('page-description',{attrs:{"path":[
        {label: _vm.$t('data.charge_correspondance'), route: 'loyers'}, 
        {label: _vm.$t('data.sidebar_depenses'), route: 'depenses'}, 
        {label: 'recettes', route: 'recettes'},
        {label: _vm.$t('data.finance_recharges'), route: 'recharges'},
        {label: _vm.$t('data.finance_cautions'), route: 'cautions',}, 
        {label: 'Indexes', route: 'indexes',}, 
    ]}}),( _vm.path == '')?_c('Loyers'):_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }