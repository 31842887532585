<template>
    <div >
        <page-description :path="[
            {label: $t('data.charge_correspondance'), route: 'loyers'}, 
            {label: $t('data.sidebar_depenses'), route: 'depenses'}, 
            {label: 'recettes', route: 'recettes'},
            {label: $t('data.finance_recharges'), route: 'recharges'},
            {label: $t('data.finance_cautions'), route: 'cautions',}, 
            {label: 'Indexes', route: 'indexes',}, 
        ]" />
        <Loyers v-if=" path == ''" />
        <router-view v-else />
    </div>
</template>

<script>
import Loyers from './Loyers.vue'
export default {
    components : {
        Loyers
    },
    data: () => ({
        path: ""
    }), 
    created() {
        this.getPath()
    },
    mounted() {
        this.getPath()
    },
    updated() {
        this.getPath()
    },
    methods: {
        getPath() {
            this.path = this.$route.path.replace('/finances', '')
        }
    }
}
</script>

<style>
</style>